$unimed_laranja: rgb(238, 114, 2);
$unimed_verde: rgb(13, 94, 83);
$unimed_verde_claro: rgb(187, 208, 48);
$color121: rgb(21, 141, 183);
$header: rgb(28, 31, 48);
$light: #fff;
$dark: #000;
$blue-dark: #181623;
$blue-light: #262a3e;
$sidebar: #202335;

$size-body: calc(100vh - 80px);

$box1: #3a386b;
$box2: #71be0c;
$box3: #36d7ca;
$box4: #1f89ff;
$box5: $color121;
