@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../base/typography";

@mixin button($w, $h, $bg: $color121, $color: $light) {
	width: $w;
	height: $h;
	background: $bg;
	padding: .3rem;
	font-family: $title;
	color: $color;
	border:none;
	border-radius: 4px;
	transition: ease-in-out .3s;
	font-size:1rem;
	cursor:pointer;

	&:hover {
		background: darken($bg, 15%);
		color: darken($light, 25%);
	}
}

@mixin buttonTheme($w, $h) {
	width: $w;
	height: $h;
	padding: .3rem;
	font-family: $title;
	border:none;
	border-radius: 4px;
	transition: ease-in-out .3s;
	font-size:1rem;
	cursor:pointer;

	&:hover {
		opacity: .8;
	}
}

@mixin buttonOutline($w, $h, $b, $bg, $c, $cH) {
	width: $w;
	height: $h;
	background: transparent;
	padding: .3rem;
	font-family: $title;
	color: $c;
	border:none;
	border-radius: 4px;
	transition: ease-in-out .3s;
	font-size:1rem;
	cursor:pointer;
	border: 1px solid $b;

	&:hover {
		background: $bg;
		color: $cH;
	}
}