@mixin d-flex($a: center, $j: center, $d: row) {
	display:flex;
	align-items: $a;
	justify-content: $j;
	flex-direction: $d;
}

@mixin text($w, $s, $t) {
	font: $w $s $t;	
}

@mixin resetInput {
	border:0;
	box-shadow: 0;
	outline: 0;
}