@mixin col-sm {
	width: 540px;
	margin:0 auto;
}

@mixin col-md {
	width: 720px;
	margin:0 auto;
}

@mixin col-lg {
	width: 960px;
	margin:0 auto;
}

@mixin col-xl {
	width: 1140px;
	margin:0 auto;
}