@import "../../styles/main";

#container-notfound {
  height: 100vh;

  @include d-flex(center, center, column);

  h3 {
    margin: 1rem 0 .5rem 0;
    @include text(normal, 1.5rem, $title);
    color: #333;
  }

  a {
    button {
      @include button(150px, 40px);
    }
  }
}

@media (min-width: 300px) {
  #container-notfound {
    img {
      width: 95%;
    }

    h3 {
      text-align:center;
    }
  }
}

@media (min-width: 768px) {
  #container-notfound {
    img {
      width: 768px;
    }
  }
}