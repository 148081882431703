@import "../../styles/base/typography";
@import "../../styles/abstracts/variables";

@mixin link {
  font-size:1.1rem;
  margin:0;
  font-family: $title;
  text-decoration:none;
  color: $light;
  transition: .3s;

  &:hover {
    color: darken($light, 25%);
  }
}