@import "../abstracts/variables";
@import "../base/typography";

@mixin input() {
	width: 100%;
	height: 38px;
	padding:0 1rem;
	background: $blue-light;
	border:none;
	border-radius:4px;
	color: $light;
	text-align:center;
	@include text(bold, 1rem, $text-input);
	margin-top:1rem;
	position:relative;

	&::placeholder {
		font-family: $title;
		text-align:center;
		font-weight:400;
	}
}